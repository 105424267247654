import React, { useMemo, useEffect, useState, useCallback } from 'react'
import { Grid, Typography, LinearProgress, Link } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { ThemeProvider } from '@material-ui/core/styles'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, Redirect, redirectTo, navigate } from '@reach/router'
import moment from 'moment'

import Loading from '_components/loading'
import SelfLearning from '_components/self-learning'
import SelfLearningTemplate from '_components/self-learning/self-learning-template'
import SkipStepModal from '_components/modal/skip-step'
import {
  createServiceOrderForm,
  getAgencyBySlug,
  getDecrypt,
  getFormAgencyCustomFields,
} from '_modules/form/actions'
import {
  formSelector,
  uuidSelector,
  agencySelector as formAgencySelector,
} from '_modules/form/selectors'
import { agencySelector } from '_/modules/agency/selectors'
import { getUser } from '_modules/authentication/actions'
import { dayPeriodsSelector } from '_modules/day-period/selector'
import { getUserLoadingSelector, userSelector } from '_modules/authentication/selectors'
import useCepAutoComplete from '_hooks/use-cep-autocomplete'
import useRolePermission from '_hooks/use-role-permission'
import { HEADER_TAGS } from '_utils/header-tags'
import { ROUTES } from '_utils/constants'
import { getGenericParametersNewForm, postStatusChange } from '_modules/service-orders/actions'
import { getAgency } from '_/modules/agency/actions'

import {
  StartPage,
  ContactPage,
  PropertyPage,
  DateSuggestions,
  AttachmentsPage,
  PropertyOwnerPage,
  FinalPage,
} from './pages'
import { theme } from './theme'
import {
  handleVerifyAgency,
  verifyCEP,
  FORM_STEPS,
  MODAL_MESSAGES,
  FORM_FIELDS,
} from './utils/general'
import ButtonBox from './button-box'
import useStyles from './styles'
import { updateServiceOrderForm } from './utils/requests'
import { linksSelector } from '_/modules/lgpdLinks/selector'
import { getLinks } from '_/modules/lgpdLinks/actions'
import SimpleConfirmModal from '_/components/modal/simple-confirm-modal'
import { finalizeServiceOrderForm, getServiceOrderForm } from '_/services/form'
import { camelize } from 'humps'

const ServiceForm = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { agencySlug } = useParams()
  const { pathname, search } = useLocation()
  const dayPeriods = useSelector(dayPeriodsSelector)

  useEffect(() => {
    if (agencySlug) {
      Promise.resolve(dispatch(getAgencyBySlug(agencySlug)))
        .then(({ id }) => {
          dispatch(getFormAgencyCustomFields(id)).catch(e => console.error(e))

          dispatch(
            getGenericParametersNewForm({
              name: 'new_service_order_form',
              agencyId: id,
            })
          ).then(genericParameters => {
            if (genericParameters?.active) navigate(`/solicitar-servico/${agencySlug}${search}`)
            else setPageLoading(false)
          })
        })
        .catch(err => handleVerifyAgency(err, setValidAgency))
    }
  }, [agencySlug, dispatch, search])

  const { STEP_ONE, STEP_TWO, STEP_THREE, STEP_FOUR, STEP_FIVE, STEP_SIX } = FORM_STEPS

  const menuProps = useMemo(
    () => ({
      classes: { paper: styles.subjectField },
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    }),
    []
  )

  // ---------------------------------- INFOS
  const { isIntermediary } = useRolePermission()
  const user = useSelector(userSelector)?.toJS()
  const userAgency = useSelector(agencySelector)?.toJS()
  const formAgency = useSelector(formAgencySelector)
  const uuid = useSelector(uuidSelector)
  const form = useSelector(formSelector)
  const [validAgency, setValidAgency] = useState()
  const loading = useSelector(getUserLoadingSelector)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const [warning, setWarning] = useState(false)
  const [progress, setProgress] = useState(0)
  const [skipDateSuggestion, setSkipDateSuggestion] = useState(false)
  const [submitForm, setSubmitForm] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [progressBar, setProgressBar] = useState(progress)
  const [autoComplete, setAutoComplete] = useState()
  const [propertyData, setPropertyData] = useState({})
  const [modalMessage, setModalMessage] = useState('')
  const [numberOfPages, setNumberOfPages] = useState(5)
  const [hasPropertyPage, setHasPropertyPage] = useState(numberOfPages === 6)
  const [currentFormFields, setCurrentFormFields] = useState([])

  const agency = useMemo(() => {
    if (userAgency) {
      return userAgency
    }

    if (formAgency) {
      return formAgency
    }

    return {}
  }, [userAgency, formAgency])

  const supportService = agency?.supportService

  const [serviceOrderId, setServiceOrderId] = useState()
  const [serviceOrder, setServiceOrder] = useState({})
  const [agencyPhone, setAgencyPhone] = useState()
  const [warningModal, setWarningModal] = useState(false)
  const [encrypted, setEncrypted] = useState(false)
  const lgpdLinks = useSelector(linksSelector)

  // ---------------------------------- FORM DATA
  const methods = useForm({
    mode: 'all',
  })
  const { register, watch, setValue, handleSubmit, errors } = methods
  const data = watch()
  const [payload, setPayload] = useState({})

  // ---------------------------------- FUNCTIONS

  const queryData = () => {
    const queryObject = {}
    new URLSearchParams(search).forEach((value, key) => {
      if (key === 'cpfInquilino' || key === 'cpfProprietario') {
        // IF CPF/CNPJ
        queryObject[`${key}`] = value?.replace(/[(). +-]/g, '')
      } else if (
        key === 'emailInquilino' ||
        key === 'emailProprietario' ||
        key === 'emailResponsavel'
      ) {
        // IF EMAIL
        queryObject[`${key}`] = value?.replace(/[()+-]/g, '')
      } else if (key === 'cep') {
        // IF CEP
        queryObject[`${key}`] = verifyCEP(value)?.replace(/[(). +]/g, '')
      } else if (key === 'idChamado') {
        // IF PRE-MADE SERVICE ORDER (QUINTO ANDAR)
        setServiceOrderId(value)
      } else if (key === 'valorAluguel') {
        // IF RENT VALUE
        queryObject[`${key}`] = parseFloat(value)
      } else if (key === 'dataInicioContrato') {
        // IF START CONTRACT DATE
        queryObject[`${key}`] = value
      } else {
        // ALL OTHERS
        queryObject[`${key}`] = value?.replace(/[().+-]/g, '')
      }
    })

    const isEncrypted =
      queryObject?.criptografado && queryObject.criptografado.toString().toLowerCase() === 'true'

    setEncrypted(isEncrypted)

    if (isEncrypted) {
      queryObject.encrypted = true
      dispatch(
        getDecrypt({
          nomeInquilino: queryObject.nomeInquilino,
          emailInquilino: queryObject.emailInquilino,
          celularInquilino: queryObject.celularInquilino,
          cpfInquilino: queryObject.cpfInquilino,
          agencySlug,
        })
      ).then(decryptedValues => {
        queryObject.nomeInquilino = decryptedValues.nomeInquilino
        queryObject.emailInquilino = decryptedValues.emailInquilino
        queryObject.celularInquilino = decryptedValues.celularInquilino
        queryObject.cpfInquilino = decryptedValues.cpfInquilino

        setAutoComplete(() => queryObject)
        setPropertyData(() => {
          return {
            property: {
              propertyName: queryObject?.nomeProprietario,
              propertyWhatsapp: isEncrypted
                ? queryObject?.celularProprietario
                : queryObject?.celularProprietario?.replace(/[(). +-]/g, ''),
              propertyEmail: queryObject?.emailProprietario,
              cpfOrCnpj: isEncrypted
                ? queryObject?.cpfProprietario
                : queryObject?.cpfProprietario?.replace(/[(). +-]/g, ''),
            },
          }
        })
      })
    } else {
      setAutoComplete(() => queryObject)
      setPropertyData(() => {
        return {
          property: {
            propertyName: queryObject?.nomeProprietario,
            propertyWhatsapp: isEncrypted
              ? queryObject?.celularProprietario
              : queryObject?.celularProprietario?.replace(/[(). +-]/g, ''),
            propertyEmail: queryObject?.emailProprietario,
            cpfOrCnpj: isEncrypted
              ? queryObject?.cpfProprietario
              : queryObject?.cpfProprietario?.replace(/[(). +-]/g, ''),
          },
        }
      })
    }
  }

  const formattedPayload = useMemo(() => {
    if (payload?.clientType === 'property_owner_client')
      return {
        ...payload,
        contractCode: payload?.contractCode,
        property: {
          propertyName: payload?.contactName,
          propertyWhatsapp: payload?.contactNumber,
          propertyEmail: payload?.contactEmail,
          cpfOrCnpj: payload?.contactCpfCnpj,
        },
      }

    if (payload?.clientType === 'agency_worker_client')
      return {
        ...payload,
        contractCode: payload?.contractCode,
        property: {
          propertyName: payload?.propertyName,
          propertyWhatsapp: payload?.propertyWhatsapp,
          propertyEmail: payload?.propertyEmail,
          cpfOrCnpj: payload?.cpfOrCnpj,
        },
      }

    if (payload?.contactNumberConfirmation) {
      // DELETES "CONTACT NUMBER CONFIRMATION" FIELD AS IT SHOULDN'T BE SENT IN THE REQUEST
      delete payload.contactNumberConfirmation
    }

    return payload
  }, [payload])

  const createServiceOrder = useCallback(() => {
    const {
      attachments,
      firstPeriod,
      secondPeriod,
      thirdPeriod,
      firstSuggestionDate,
      secondSuggestionDate,
      thirdSuggestionDate,
      contractStartDate,
      uf,
      // eslint-disable-next-line no-unused-vars
      optionsSelectedReprovedService,
      ...otherValues
    } = formattedPayload // Extracts properties to exclude from payload and treat separately below

    const getPeriod = (period, startOrEnd, customTime) =>
      period === 'custom'
        ? moment(customTime).format('HH:mm:ss')
        : dayPeriods?.find(dayPeriod => dayPeriod.id === period)[startOrEnd]

    // const formattedContractDate = moment(contract_start_date).format('yyyy-MM-DD') || null

    const commonPayload = {
      ...otherValues,
      contractStartDate: contractStartDate && moment(contractStartDate).format('yyyy-MM-DD'),
      agency: agency.id,
      description: payload?.propertyKeysLocation
        ? `${otherValues.description}\n\nLocalização das chaves: ${otherValues.propertyKeysLocation}`
        : otherValues.description,
      property: {
        ...otherValues.property,
        uf,
      },
    }

    const updatePayload = {
      ...otherValues,
      contractStartDate: contractStartDate && moment(contractStartDate).format('yyyy-MM-DD'),
      agency: agency.id,
      description: payload?.propertyKeysLocation
        ? `${otherValues.description}\n\nLocalização das chaves: ${otherValues.propertyKeysLocation}`
        : otherValues.description,
      property: {
        ...otherValues.property,
        uf,
      },
    }

    const dateSuggestionPayload = () => ({
      firstSuggestionDate: moment(firstSuggestionDate).format('yyyy-MM-DD'),
      firstStartTime: getPeriod(firstPeriod, 'startTime', otherValues.firstStartTime),
      firstEndTime: getPeriod(firstPeriod, 'endTime', otherValues.firstEndTime),
      secondSuggestionDate: moment(secondSuggestionDate).format('yyyy-MM-DD'),
      secondStartTime: getPeriod(secondPeriod, 'startTime', otherValues.secondStartTime),
      secondEndTime: getPeriod(secondPeriod, 'endTime', otherValues.secondEndTime),
      thirdSuggestionDate: moment(thirdSuggestionDate).format('yyyy-MM-DD'),
      thirdStartTime: getPeriod(thirdPeriod, 'startTime', otherValues.thirdStartTime),
      thirdEndTime: getPeriod(thirdPeriod, 'endTime', otherValues.thirdEndTime),
    })

    const finalPayload = {
      ...commonPayload,
      ...(!skipDateSuggestion && dateSuggestionPayload()),
      ...{ encrypted },
      // Adding the && as conditional, it'll only add dateSuggestionPayload into `finalPayload` if skipDateSuggestion is false
    }

    const finalUpdatePayload = {
      ...updatePayload,
      ...(!skipDateSuggestion && dateSuggestionPayload()),
      category: updatePayload?.category?.map(category => category.id),
      responsible: updatePayload?.responsible?.id,
      // Adding the && as conditional, it'll only add dateSuggestionPayload into `finalPayload` if skipDateSuggestion is false
    }

    const finalPropertyPayload = {
      ...data,
      serviceOrderId: serviceOrder?.id,
    }

    setButtonLoading(true)

    if (serviceOrderId) {
      // UPDATES EXISTING SERVICE ORDER (QUINTO ANDAR)
      delete finalUpdatePayload.property
      delete finalPropertyPayload.attachments
      Promise.resolve(updateServiceOrderForm(null, serviceOrderId, finalUpdatePayload)).then(
        res => {
          Promise.resolve(updateServiceOrderForm(null, res.id, { attachments })).then(rest => {
            setButtonLoading(false)
            setProgress(prevState => prevState + 1)
            finalPropertyPayload.service_order_id = rest.id
          })
        },
        () => {
          // ERROR
          setButtonLoading(false)
        }
      )
    } else {
      dispatch(createServiceOrderForm(finalPayload))
        .then(() => onCreateServiceOrderSuccessful())
        .finally(() => setButtonLoading(false))
    }
  }, [dayPeriods, payload])

  const onCreateServiceOrderSuccessful = useCallback(() => {
    const updateServiceOrderPayload = formattedPayload?.attachments
      ? {
          attachments: formattedPayload?.attachments,
        }
      : {}

    setProgress(prevState => prevState + 1)
    updateServiceOrderForm(uuid, form.id, updateServiceOrderPayload)
    finalizeServiceOrderForm(uuid)(form.id)
    setButtonLoading(false)
  }, [formattedPayload?.attachments, uuid, form])

  const handleSkipStep = useCallback(() => {
    currentFormFields.forEach(field => {
      setValue(field, null)
    })
    setIsModalOpen(false)
    setProgress(progress + 1)
  }, [progress, currentFormFields])

  const onSubmit = useCallback(() => {
    // THE REAL REQUEST HAPPENS INSIDE AN useEffect AS SEEN BELOW
    // THIS CODE WILL ONLY UPDATE PAYLOAD AND CHANGE PAGES
    const finalPage =
      (hasPropertyPage && progress === STEP_SIX) || (!hasPropertyPage && progress === STEP_FIVE)
    const hasErrors = Object.keys(errors).length > 0
    let finalData = { ...payload, ...data }

    FORM_FIELDS.HIDDEN_FIELDS.forEach(field => {
      try {
        const fieldValue = autoComplete[field.queryName]
        if (fieldValue) finalData[field.name] = fieldValue
      } catch (e) {
        console.error(e)
      }
    })

    if (hasPropertyPage && progress === STEP_THREE) {
      finalData = { ...finalData, property: { ...data } }
    }

    if (!hasErrors && progress === STEP_TWO && !!propertyData) {
      finalData = { ...finalData, ...propertyData }
    }

    if (!hasErrors && !finalPage) {
      setProgress(prevState => prevState + 1)
    }

    if (finalPage && !hasErrors) {
      setSubmitForm(true)
    }

    setPayload(prevState => {
      return { ...prevState, ...finalData }
    })
  }, [data])

  // ---------------------------------- REQUESTS
  useEffect(() => {
    const acceptedStatus =
      serviceOrder.stepStatusId === 'waiting_first_comunication' ||
      serviceOrder.stepStatusId === 'refera_service_denied'

    const canceledStatus = serviceOrder.stepStatusId === 'service_order_canceled'

    if (canceledStatus) {
      setWarningModal(() => true)
    }

    if (acceptedStatus) {
      dispatch(postStatusChange(serviceOrder?.id))
    }
  }, [serviceOrder])

  useEffect(() => {
    if (serviceOrder.agency) {
      dispatch(getAgency(serviceOrder?.agency?.id)).then(res => setAgencyPhone(res?.phoneNumber))
    }
  }, [serviceOrder])

  useEffect(() => {
    if (serviceOrderId) {
      // RELATED TO QUINTO ANDAR NEEDS
      // FETCHES AN ALREADY EXISTING SERVICE ORDER AND USES IT FOR DEFAULT VALUES
      Promise.resolve(getServiceOrderForm(serviceOrderId, undefined)).then(
        res => {
          // SUCCESS
          const camelized = camelize(res)
          setServiceOrder(camelized)
          if (res.stepStatusId !== 'service_order_canceled') {
            setProgress(prevState => prevState + 1)
            setPayload({ ...camelized, ...camelized.property })
          }
        },
        () => {
          // ERROR
          setButtonLoading(false)
        }
      )
    }
  }, [serviceOrderId])

  useEffect(() => {
    const finalPage =
      (hasPropertyPage && progress === STEP_SIX) || (!hasPropertyPage && progress === STEP_FIVE)
    const hasErrors = Object.keys(errors).length > 0

    if (!hasErrors && finalPage && submitForm) {
      // CREATES SERVICE ORDER
      createServiceOrder()
    }
  }, [setPayload, payload])

  useEffect(() => {
    Promise.resolve(dispatch(getUser())).catch(e => {
      if (e.detail.includes('Auth') && !lgpdLinks.length) {
        dispatch(getLinks('lessee'))
      }
    })
  }, [dispatch])

  // ---------------------------------- REDIRECTS
  useEffect(() => {
    if (!agencySlug || (pathname.slice(-1) !== '/' && !agencySlug)) {
      redirectTo('/link-expirado')
    }

    if (validAgency === false) {
      redirectTo('/link-expirado')
    }
  }, [agencySlug, validAgency])

  const wppRedirect = () => {
    if (agencyPhone) {
      window.open(`https://wa.me/${agencyPhone}`, '_blank')
    } else {
      window.open(`https://refera.movidesk.com/form/6753/`, '_blank')
    }
  }

  // ---------------------------------- USER RULES
  useEffect(() => {
    if (search?.length > 0) {
      queryData()
    }
  }, [search])

  useEffect(() => {
    if (hasPropertyPage) {
      if (progress === STEP_ONE) {
        setProgressBar({ title: 'Vamos começar: que tipo de cliente você é?', stage: 0 })
      } else if (progress === STEP_TWO) {
        setProgressBar({
          title: 'Conte mais sobre seus problemas',
          stage: 16,
        })
      } else if (progress === STEP_THREE) {
        setProgressBar({
          title: 'Como a gente pode falar com você?',
          stage: 32,
        })
        setCurrentFormFields(FORM_FIELDS.DATE_SUGGESTION)
        setModalMessage(MODAL_MESSAGES.DATE_SUGGESTION)
      } else if (progress === STEP_FOUR) {
        setModalMessage(MODAL_MESSAGES.DATE_SUGGESTION)
        setProgressBar({
          title:
            'Caso seja necessário, quando o prestador pode realizar uma visita para montar o orçamento?',
          stage: 48,
        })
      } else if (progress === STEP_FIVE) {
        setProgressBar({
          title: 'Conte um pouco sobre o proprietário do imóvel',
          stage: 64,
        })
        setCurrentFormFields(FORM_FIELDS.PROPERTY_OWNER)
        setModalMessage(MODAL_MESSAGES.PROPERTY_OWNER)
      } else if (progress === STEP_SIX) {
        setProgressBar({
          title: 'E pra finalizar: conta um pouco sobre o seu imóvel',
          stage: 80,
        })
      } else {
        setProgressBar({ title: '', stage: 100 })
      }
    }

    if (!hasPropertyPage) {
      if (progress === STEP_ONE) {
        setProgressBar({ title: 'Vamos começar: que tipo de cliente você é?', stage: 0 })
      } else if (progress === STEP_TWO) {
        setProgressBar({
          title: 'Conte mais sobre seus problemas',
          stage: 20,
        })
      } else if (progress === STEP_THREE) {
        setProgressBar({
          title:
            'Caso seja necessário, quando o prestador pode realizar uma visita para montar o orçamento?',
          stage: 40,
        })
      } else if (progress === STEP_FOUR) {
        setProgressBar({
          title: 'Como a gente pode falar com você?',
          stage: 60,
        })
      } else if (progress === STEP_FIVE) {
        setProgressBar({
          title: 'E pra finalizar: conta um pouco sobre o seu imóvel',
          stage: 80,
        })
      } else {
        setProgressBar({ title: '', stage: 100 })
      }
    }
  }, [progress])

  useEffect(() => {
    if (isIntermediary) {
      register('clientType')
      setValue('clientType', 'agency_worker_client')
    }
  }, [isIntermediary])

  useEffect(() => {
    if (!user?.agency && data?.clientType === 'agency_worker_client') {
      setValue('clientType', '')
      setWarning(true)
    }
  }, [user, data])

  useEffect(() => {
    if (data?.clientType && data?.clientType !== 'agency_worker_client') setWarning(() => false)
    if (
      (payload?.clientType === 'agency_worker_client' ||
        data?.clientType === 'agency_worker_client') &&
      isIntermediary
    ) {
      setNumberOfPages(6)
    } else {
      setNumberOfPages(5)
    }
  }, [payload?.clientType, data?.clientType, isIntermediary])

  useEffect(() => {
    setHasPropertyPage(numberOfPages === 6)
  }, [numberOfPages])

  useEffect(() => {
    register('uf')
  }, [])

  const cepData = useCepAutoComplete(data?.cep, true)

  useEffect(() => {
    if (cepData?.address?.length) {
      setValue('neighborhood', cepData?.neighborhood || '')
      setValue('city', cepData?.city || '')
      setValue('address', cepData?.address || '')
      setValue('uf', cepData?.state || '')
    }
  }, [cepData])

  if (pathname.slice(-1) === '/') {
    return <Redirect noThrow to={pathname.slice(0, -1)} />
  }

  if (loading || pageLoading) {
    return <Loading />
  }
  return (
    <ThemeProvider theme={theme}>
      {HEADER_TAGS[ROUTES.SETUP_AGENCY_FORM].header}
      <Grid className={styles.container}>
        <Grid className={styles.header} component="header">
          <LinearProgress
            className={styles.linearProgress}
            variant="determinate"
            value={progressBar?.stage}
          />
          <Grid className={styles.headerContent}>
            <img src={agency?.brandLogoPngUrl} alt="Logo da imobiliária" className={styles.logo} />
            <Typography
              align="center"
              variant="h3"
              component="h2"
              color="textSecondary"
              gutterBottom
            >
              {progress > STEP_FIVE
                ? 'Solicitação de manutenção concluída'
                : `Solicitação de manutenção - Passo ${progress + 1} de ${numberOfPages}`}
            </Typography>
            <Grid className={styles.titleGrid}>
              <Typography
                align="center"
                variant="h1"
                component="h2"
                color="textPrimary"
                gutterBottom
                className={styles.stepTitle}
              >
                {progressBar.title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {(!hasPropertyPage && progress >= STEP_SIX) || progress > STEP_SIX ? (
          <FinalPage payload={payload} />
        ) : (
          <FormProvider {...methods}>
            <form id="serviceOrderForm" className={styles.main} onSubmit={handleSubmit(onSubmit)}>
              {progress === STEP_ONE && (
                <StartPage
                  payload={payload}
                  data={data}
                  menuProps={menuProps}
                  autoComplete={autoComplete}
                  serviceOrderId={serviceOrderId}
                />
              )}
              {progress === STEP_TWO && <AttachmentsPage data={data} payload={payload} />}
              {((!hasPropertyPage && progress === STEP_THREE) ||
                (hasPropertyPage && progress === STEP_FOUR)) && (
                <>
                  <DateSuggestions payload={payload} data={data} menuProps={menuProps} />
                  <SelfLearning
                    hasMobile
                    message="Quer entender melhor como funcionam as sugestões de horário?"
                    mobileMessage="Posso ajudar?"
                    modalTitle="Sugestões de horário"
                    modalTemplate={<SelfLearningTemplate />}
                    supportService={supportService ?? ''}
                  />
                </>
              )}
              {((!hasPropertyPage && progress === STEP_FOUR) ||
                (hasPropertyPage && progress === STEP_THREE)) && (
                <ContactPage
                  data={data}
                  autoComplete={autoComplete}
                  menuProps={menuProps}
                  payload={payload}
                />
              )}
              {hasPropertyPage && progress === STEP_FIVE && (
                <PropertyOwnerPage data={data} propertyData={propertyData?.property} />
              )}
              {((!hasPropertyPage && progress === STEP_FIVE) ||
                (hasPropertyPage && progress === STEP_SIX)) && (
                <PropertyPage
                  data={data}
                  autoComplete={autoComplete}
                  menuProps={menuProps}
                  payload={payload}
                />
              )}
              {warning && (
                <Alert severity="error" variant="filled">
                  <Typography variant="body1">
                    Para abrir um chamado como &quot;Funcionário da Imobiliária&quot;, acesse o
                    BackOffice, clique na lista de chamados e em seguida no botão Novo Chamado.
                  </Typography>
                </Alert>
              )}
              {((hasPropertyPage && progress <= STEP_SIX) || progress <= STEP_FIVE) && (
                <div className={styles.warningPlusButtonContainer}>
                  {progress === STEP_ONE && (
                    <Typography className={styles.LGPDWarning}>
                      Ao continuar, você concorda com as{' '}
                      <Link
                        href={user?.lgpdLinks ? user?.lgpdLinks[1] : lgpdLinks?.privacyPolicy}
                        target="_blank"
                      >
                        Políticas de Privacidade
                      </Link>{' '}
                      da Refera.
                    </Typography>
                  )}
                  <ButtonBox
                    data={data}
                    hasPropertyPage={hasPropertyPage}
                    progress={progress}
                    setProgress={setProgress}
                    buttonLoading={buttonLoading}
                    setIsModalOpen={setIsModalOpen}
                    setSkipDateSuggestion={setSkipDateSuggestion}
                  />
                </div>
              )}
            </form>
          </FormProvider>
        )}

        <SkipStepModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleSkipStep={handleSkipStep}
          modalMessage={modalMessage}
        />

        {warningModal && (
          <SimpleConfirmModal
            isModalOpen={warningModal}
            setIsModalOpen={setWarningModal}
            title="Chamado cancelado!"
            confirmText="Quero reabrir o chamado"
            handleSubmit={() => wppRedirect()}
            hasCancel={false}
          >
            <Typography>
              O chamado {serviceOrder.id} já foi cancelado. Caso queira reabrí-lo entre em contato
              com nosso suporte através do WhatsApp.
            </Typography>
          </SimpleConfirmModal>
        )}

        <Grid component="footer" className={styles.footer}>
          <Typography align="center" component="p" variant="h4" color="textSecondary">
            © 2021 Refera Tecnologia - Desenvolvido por Refera
          </Typography>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}

export default ServiceForm
